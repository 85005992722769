<template>
  <div class="mainWrap">
    <van-nav-bar
      fixed
      title="新闻详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <p class="autoline title">{{ itemData.title }}</p>
    <div class="tagWrap">
      <p class="tag-time">{{ itemData.modified | dayjs }}</p>
      <van-tag class="tagCategory" @click="goToNewsList">{{ category }}</van-tag>
    </div>
    <p class="autoline des">
      {{ itemData.cn_content }}
    </p>
    <div class="location-wrap">
      <p class="location" v-if="(itemData.state_cn||itemData.ncov_school_cn)">
        <a-icon class="icon" type="environment"></a-icon>
        <span @click="goToState" class="stateName">{{ itemData.state_cn }}</span>
        <span class="sep" v-if="itemData.state_cn&&itemData.ncov_school_cn">|</span>
        <span @click="goToSchool" class="schoolName">{{ itemData.ncov_school_cn }}</span>
      </p>
      <!-- <div>
        <a-button type="primary" size="mini" @click="handleShare">分享</a-button>
      </div> -->
    </div>

    <p class="link" v-if="itemData.original_url">
      来源：
      <a :href="itemData.original_url" target="_blank">{{ itemData.original_url }}</a>
    </p>
  </div>
</template>

<script>
import { backInitMixin } from '@/utils/mixin'
import { mapMutations } from 'vuex'
export default {
  name: 'NewsDetail',
  components: {

  },
  mixins: [backInitMixin],
  props: {

  },
  data () {
    return {
      itemData: {}
    }
  },
  computed: {
    category () {
      if (this.itemData.node_category) {
        console.log('ta', this.itemData.node_category.split('-')[0])
        return `#${this.itemData.node_category.split('-')[0]}#`
      } else {
        return ''
      }
    }
  },
  watch: {

  },
  created () {
    this.name = this.$route.query.name
    this.params = {
      filters: { name: '', node_category: this.$utils.newsCategory.all }
    }

    this.params.filters.name = this.name
    this._getData()
  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setShareDomNews: 'SET_SHARE_DOM_NEWS'
    }),
    goToNewsList () {
      const tagList = [

        {
          name: '重要播报',
          recommended: 'Index Top Slide',
          tips: ''

        },
        {
          name: '校园疫情',
          tips: this.$utils.newsCategory.news,
          key: 'news'
        },
        {

          name: '学业新闻',
          tips: this.$utils.newsCategory.study,
          key: 'study'
        },
        {
          name: '实习就业',
          tips: this.$utils.newsCategory.job,
          key: 'job'
        },
        {
          name: '使馆信息',
          tips: this.$utils.newsCategory.embassy,
          key: 'embassy'
        },
        {
          name: '疫苗进展',
          tips: this.$utils.newsCategory.vaccine,
          key: 'vaccine'
        },
        {
          name: '身份/出入境',
          tips: this.$utils.newsCategory.identity,
          key: 'identity'
        },
        {
          name: '联邦新闻',
          tips: this.$utils.newsCategory.federal,
          key: 'federal'
        },

        {
          name: '机票行情',
          tips: this.$utils.newsCategory.ticket,
          key: 'ticket'
        }

      ]
      const category = this.category.replace(/#/g, '')
      const index = tagList.findIndex(item => {
        return item.name === category
      })
      console.log(category, index, 'tt')
      this.$router.push({
        path: '/news',
        query: {
          item: tagList[index],
          index: index + 1
        }

      })
    },
    handleShare () {
      const wx = window.wx

      wx.miniProgram.navigateTo({
        url: `/pages/browser/index?route=newsDetail&name=${this.name}`,
        success: function () {
          console.log('success')
        },
        fail: function () {
          console.log('fail')
        },
        complete: function () {
          console.log('complete')
        }
      })
    },
    _getData () {
      this.$api['home/news'](this.params)
        .then(res => {
          if (res.message) {
            this.itemData = res.message[0]
            this.setShareDomNews(res.message[0])
          }
        })
    },
    goToState () {
      this.$router.push({
        path: '/state',
        query: {
          name: this.itemData.state,
          short_name: this.itemData.state_shortname
        }
      })
    },
    goToSchool () {
      this.$router.push({
        path: '/school',
        query: {
          name: this.itemData.ncov_school,
          state: this.itemData.state_shortname

        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.mainWrap{
  background:#f7f7f7;
  padding:46px 16px ;
  text-align:left;
  background:#fff;
  .tagWrap{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:10px;
    .tag-time {
      color: #999;
    }
    span{
      margin:0
    }
    button{
        background:#fc8300;
        border:none
    }
  }
  .tagCategory{
    margin-top:10px;

    background: #ECF0FC;
    color: #4169E2;
    padding:3px 5px;

    font-size:14px;
  }
}
 .link{
    font-weight:400;
    font-size:12px;
    margin-top:12px;
    color:#b4b4b4;
    display:-webkit-box;
    word-break: break-all;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;

    a{
        color:#b4b4b4
    }

}
.location-wrap{
   display: flex;
    flex-direction: row;
  justify-content: space-between;
  align-items: center;
    button {
      background: #fc8300;
      border: none;
      margin: 0;
  }
}
.location{
  font-weight:400;
  font-size:12px;
  margin-top:12px;
  margin-bottom:12px;
  .icon{
    margin-right:10px;
    color:#999;
    font-size:14px;
    position:relative;
    top:1px;
  }
  .stateName{
    font-weight:bold;
    color:@primary-color;
  }
  .sep{
    margin:0 10px;
    color:#333;

  }
  .schoolName{
    font-weight:bold;
    color:#f27355
  }
}
.autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size:14px;
    color:#555;
    margin-bottom:12px;
    &.title{
       font-size:16px;
       font-weight:550;
       margin-top:10px;
    }
    &.des{
      color:#333;
      line-height:25px;
      font-size:14px;
    }

}
p{
    margin:0;
}
</style>
